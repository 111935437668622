import { navigate } from 'gatsby'
import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'

function Callback({ location }) {
  const { user } = useIdentity()

  if (!windowExists || user.loading) {
    return null
  }

  const returnTo =
    (location && location.state && location.state.returnTo) || '/'
  navigate(returnTo, { replace: true })
  return null
}

export default Callback
